<template>
	<div class="form mb-3">
		<b-row>
			<b-col cols="6" class="d-flex align-items-center">
				<button
					class="d-flex justify-content-end align-items-center text-color-blue-streak fw-500 fs-14 btn-transparent mr-3"
					@click="handleClickShowFilter(!tagVisible)"
				>
					<div v-if="tagVisible">{{ FormMSG(25, 'Hide filter') }}</div>
					<div v-if="!tagVisible">{{ FormMSG(26, 'Show filter') }}</div>
					<chevron-up v-if="tagVisible" :size="20" color="#225CBD" class="ml-1" />
					<chevron-down v-if="!tagVisible" :size="20" color="#225CBD" class="ml-1" />
				</button>
				<button
					class="d-flex justify-content-end align-items-center fw-500 fs-14 btn-transparent"
					:class="{
						'text-color-blue-streak': !haveFilter,
						'text-color-tree-sap': haveFilter
					}"
				>
					<info :size="18" class="mr-1" />
					<div v-if="haveFilter">{{ FormMSG(27, 'Active filter') }}</div>
					<div v-if="!haveFilter">{{ FormMSG(101, 'No active filter') }}</div>
				</button>
				<b-button v-if="haveFilter" variant="outline-primary" size="sm" class="ml-5" @click="resetFilter">
					{{ FormMSG(28, 'Reset filter') }}
				</b-button>
			</b-col>
			<b-col cols="6">
				<div class="float-right">
					<div class="d-inline-block d-flex justify-content-end align-items-center">
						<b-button
							v-if="!hideButtonRefreshData"
							variant="success"
							class="mr-2 d-flex justify-content-end align-items-center"
							@click="handleClickRefreshData"
						>
							<RefreshCw :size="16" />
							<div class="ml-2" style="margin-top: 1px">{{ FormMSG(136, 'Refresh data') }}</div>
						</b-button>
						<b-button
							v-if="!hideButtonExport"
							class="d-flex mr-2 justify-content-end align-items-center"
							variant="primary"
							@click="handleClickExportSelection"
						>
							<Download :size="16" />
							<div class="ml-2" style="margin-top: 1px">{{ FormMSG(29, 'Export selection') }}</div>
						</b-button>
						<b-button v-if="showImport" class="d-flex justify-content-end align-items-center" variant="primary" @click="redirectToImportScreen">
							<div style="margin-top: 1px; width: 130px">{{ FormMSG(290, 'Import') }}</div>
						</b-button>
					</div>
				</div>
			</b-col>
		</b-row>
		<b-collapse :visible="tagVisible" class="mt-2">
			<fieldset v-if="!hideDate" class="scheduler-border border-groove-blue-streak pb-0">
				<legend class="scheduler-border text-color-blue-streak">
					{{ FormMSG(145, 'When') }}
				</legend>
				<b-row>
					<b-col cols="3">
						<b-form-group :label="FormMSG(41, 'Start date')">
							<b-input-group class="mb-3">
								<b-datepicker
									v-model="filterActive.startDate"
									:locale="lang"
									:placeholder="FormMSG(42, 'dd/mm/yyyy')"
									:state="stateDate.startDate"
									@input="handleInputStartDate"
								/>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(43, 'End date')">
							<b-input-group class="mb-3">
								<b-datepicker
									v-model="filterActive.endDate"
									:locale="lang"
									:placeholder="FormMSG(42, 'dd/mm/yyyy')"
									:state="stateDate.endDate"
									@input="handleInputEndDate"
								/>
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-row>
			</fieldset>
			<fieldset v-if="!hidePersonSection" class="scheduler-border border-groove-blue-streak pb-0">
				<legend class="scheduler-border text-color-blue-streak">
					{{ FormMSG(146, 'Person') }}
				</legend>
				<b-row>
					<b-col cols="3">
						<b-form-group :label="FormMSG(35, 'Department')">
							<v-select
								v-model="filterActive.userDepartment"
								:options="[{ value: -1, message: FormMSG(285, 'All departments') }, ...allDepartements]"
								label="message"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(36, 'Select department')"
								:clearable="false"
								@input="handleChangeDepartment"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(37, 'Function')">
							<v-select
								v-model="filterActive.userFunction"
								:options="functionNames"
								label="message"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(38, 'Select function')"
								:disabled="filterActive.userDepartment === null || filterActive.userDepartment === -1"
								@input="handleFunctionInput"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(214, 'User')">
							<v-select
								v-model="filterActive.personId"
								:options="users"
								label="text"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(218, 'Select a user')"
							/>
						</b-form-group>
					</b-col>
				</b-row>
			</fieldset>
			<fieldset v-if="!hideExpenseDetailsSection" class="scheduler-border border-groove-blue-streak pb-0">
				<legend class="scheduler-border text-color-blue-streak">
					{{ titleExpenseDetail }}
				</legend>
				<b-row>
					<b-col cols="3">
						<b-form-group :label="FormMSG(295, 'Status')">
							<v-select
								v-model="filterActive.status"
								:options="statuses"
								label="text"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(265, 'Select a status')"
							/>
						</b-form-group>
					</b-col>
					<b-col v-if="useDepartmentForExpense && !hideExpenseDetailsDepartment" cols="3">
						<b-form-group :label="FormMSG(35, 'Department')">
							<v-select
								v-model="filterActive.department"
								:options="[{ value: -1, message: FormMSG(285, 'All departments') }, ...allDepartements]"
								label="message"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(296, 'Select a department')"
								@input="handleInputDepartmentExpense($event, true)"
							/>
						</b-form-group>
					</b-col>
					<b-col v-if="!hideExpenseDetailsCategory" cols="3">
						<b-form-group :label="FormMSG(151, 'Category')">
							<treeselect
								v-model="filterActive.category"
								:multiple="false"
								:options="mapCategories"
								:disable-branch-nodes="true"
								:placeholder="FormMSG(297, 'Select a category')"
								:clearable="false"
							>
								<div slot="option-label" slot-scope="{ node }">
									<div class="treeselect-label" :title="node.label">
										{{ node.label }}
									</div>
								</div>
							</treeselect>
						</b-form-group>
					</b-col>
					<b-col v-if="filterType === 2 || filterType === 4" cols="3">
						<b-form-group :label="FormMSG(347, 'Type')">
							<v-select
								v-model="filterActive.type"
								:options="typeExpenses"
								label="text"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(326, 'Select a type')"
							/>
						</b-form-group>
					</b-col>
					<b-col v-if="!hideProcessFilter">
						<b-form-group :label="FormMSG(514, 'Processed')" v-slot="{ ariaDescribedby }">
							<b-form-radio-group
								id="radio-group-2"
								v-model="filterActive.processed"
								:aria-describedby="ariaDescribedby"
								name="radio-sub-component"
							>
								<b-form-radio :value="false" class="mt-2">{{ FormMSG(515, 'No') }}</b-form-radio>
								<b-form-radio :value="true" class="ml-2 mt-2">{{ FormMSG(516, 'Yes') }}</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>
					<b-col v-if="!hideInvoicedToProduction">
						<b-form-group :label="FormMSG(553, 'Invoiced to production')" v-slot="{ ariaDescribedby }">
							<b-form-radio-group
								id="radio-group-3"
								v-model="filterActive.invoicedToProduction"
								:aria-describedby="ariaDescribedby"
								name="radio-sub-component-2"
							>
								<b-form-radio :value="false" class="mt-2">{{ FormMSG(515, 'No') }}</b-form-radio>
								<b-form-radio :value="true" class="ml-2 mt-2">{{ FormMSG(516, 'Yes') }}</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>
				</b-row>
			</fieldset>
			<fieldset v-if="!hideSupplierSection" class="scheduler-border border-groove-blue-streak pb-0">
				<legend class="scheduler-border text-color-blue-streak">
					{{ FormMSG(149, 'Supplier') }}
				</legend>
				<b-row>
					<b-col cols="3">
						<b-form-group :label="FormMSG(212, 'Supplier')">
							<v-select
								v-model="filterActive.supplierId"
								:options="suppliers"
								label="name"
								:reduce="reduceSupplier"
								:placeholder="FormMSG(213, 'Select a supplier')"
								@input="handleInputSupplier"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(30, 'Country')">
							<v-select
								v-model="filterActive.country"
								:options="optionsForCountries"
								label="text"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(31, 'Select country')"
								@input="handleChangeCountry"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(32, 'State / Region')">
							<v-select
								v-model="filterActive.state"
								:options="stateNames"
								:placeholder="FormMSG(128, 'Select state')"
								label="text"
								:reduce="(option) => option.value"
								:disabled="!filterActive.country"
								@input="handleInputState"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(33, 'City')">
							<b-form-input v-model="filterActive.city" :placeholder="FormMSG(33, 'City')" />
						</b-form-group>
					</b-col>
				</b-row>
			</fieldset>
			<fieldset v-if="!hideEntryDetailSection" class="scheduler-border border-groove-blue-streak pb-0">
				<legend class="scheduler-border text-color-blue-streak">
					{{ FormMSG(521, 'EntryDetail') }}
				</legend>
				<b-row>
					<b-col cols="3">
						<b-form-group :label="FormMSG(522, 'Source')">
							<v-select
								v-model="filterActive.source"
								label="text"
								:options="sourceOptions"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(523, 'Select a source')"
								:clearable="false"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(524, 'CO2 Type')">
							<v-select
								v-model="filterActive.co2Type"
								label="text"
								:options="coTwoTypeOptions"
								:placeholder="FormMSG(525, 'Select a category')"
								:reduce="(option) => option.value"
								:clearable="false"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(526, 'Review status')">
							<treeselect
								:options="reviewStatusOptions"
								:v-model="filterActive.reviewStatus"
								:value="filterActive.reviewStatus"
								:placeholder="FormMSG(894, 'Please select ...')"
								:multiple="true"
								flat
								:clearable="false"
								@input="handleInputReviewStatus"
							>
								<div
									slot="value-label"
									slot-scope="{ node }"
									:title="setTextValue(node.label)"
									class="d-flex align-items-center justify-content-center"
									style="height: 12px !important"
								>
									<div style="font-size: 10px !important">{{ setTextValue(node.label) }}</div>
								</div>
								<div slot="option-label" slot-scope="{ node }">
									<div class="treeselect-label" :title="setText(node.label)">{{ setText(node.label) }}</div>
								</div>
							</treeselect>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(212, 'Supplier')">
							<v-select
								v-model="filterActive.supplierId"
								:options="suppliers"
								label="name"
								:reduce="reduceSupplier"
								:placeholder="FormMSG(213, 'Select a supplier')"
								:clearable="false"
								@input="handleInputSupplier"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(151, 'Category')">
							<treeselect
								v-model="filterActive.category"
								:multiple="false"
								:options="mapCategories"
								:disable-branch-nodes="true"
								:placeholder="FormMSG(297, 'Select a category')"
								:clearable="false"
							>
								<div slot="option-label" slot-scope="{ node }">
									<div class="treeselect-label" :title="node.label">
										{{ node.label }}
									</div>
								</div>
							</treeselect>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(289, 'Template')">
							<v-select
								v-model="filterActive.templateId"
								:multiple="false"
								:options="carbonTemplates"
								:placeholder="FormMSG(397, 'Please select ...')"
								label="name"
								:reduce="(option) => +option.id"
								:clearable="false"
							>
								<div slot="option-label" slot-scope="{ node }">
									<div class="treeselect-label" :title="node.label">
										{{ node.label }}
									</div>
								</div>
							</v-select>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(392, 'Forecast/Final report')" v-slot="{ ariaDescribedby }">
							<b-form-radio-group
								id="radio-group-2"
								v-model="filterActive.forForecastReportOnly"
								:aria-describedby="ariaDescribedby"
								name="radio-forecast"
							>
								<b-form-radio :value="false" class="mt-2">{{ FormMSG(515, 'No') }}</b-form-radio>
								<b-form-radio :value="true" class="ml-2 mt-2">{{ FormMSG(516, 'Yes') }}</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>
				</b-row>
			</fieldset>
			<b-row>
				<b-col cols="2" offset="10">
					<b-button variant="success" block :disabled="!canApplyFilter" @click="handleClickAplyFilter">
						{{ FormMSG(291, 'Apply filter') }}
					</b-button>
				</b-col>
			</b-row>
		</b-collapse>
	</div>
</template>

<script>
import { ChevronUp, ChevronDown, Info, CalendarDays, Download, RefreshCw, SlidersHorizontal, PlusCircle, AlertTriangle } from 'lucide-vue';
import languageMessages from '@/mixins/languageMessages';
import { mapGetters, mapActions } from 'vuex';
// import gql from 'graphql-tag';
import { getSuppliersCountries, getSuppliersStates } from '@/cruds/suppliers.crud';
import { getSuppliers } from '@/cruds/suppliers.crud';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import { store } from '@/store';
import { getUsers } from '@/cruds/users.crud';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import { removeAttributeTree } from '@/shared/utils';
import GlobalFilter from '@/components/Modals/GlobalFilter';
import BudgetMixin from '@/components/Budget/budget.mixin';
import { getFunctions } from '@/cruds/department.crud';
import { CARBON_VERIFIED_STATUS_MENU } from '@/shared/constants';
import { getCarbonTemplates } from '@/cruds/carbon.crud';

/*
 * 0: Filter for budget per category
 * 1: Filter for budget salaries
 * 2: Filter for budget expenses
 * 3: Filter for budget purchase orders
 * 4: Filter for budget expense sheet
 * 5: Filter for green table
 */
const authorizedFilterTypeMode = [0, 1, 2, 3, 4, 5];

export default {
	name: 'FilterBudgetAdvanced',
	components: {
		ChevronUp,
		ChevronDown,
		Info,
		CalendarDays,
		Download,
		RefreshCw,
		Treeselect,
		PlusCircle,
		SlidersHorizontal,
		GlobalFilter,
		AlertTriangle
	},
	mixins: [languageMessages, globalMixin, BudgetMixin],
	props: {
		showImport: { type: Boolean, default: false },
		hideExportSelection: { type: Boolean, default: false },
		// initFilter: { type: Object, default: {}, required: false},
		hideSupplierSection: { type: Boolean, default: false, required: false },
		hidePersonSection: { type: Boolean, default: false, required: false },
		hideExpenseDetailsSection: { type: Boolean, default: false, required: false },
		showUserSelector: { type: Boolean, default: true, required: false },
		hideButtonExport: { type: Boolean, default: false, required: false },
		hideGeo: { type: Boolean, default: false },
		hideFunction: { type: Boolean, default: false },
		hideEntryDetailSection: { type: Boolean, default: true },
		hideButtonRefreshData: { type: Boolean, default: false },
		hideDate: { type: Boolean, default: false },
		labelExpenseDetails: { type: String, default: '' },
		hideExpenseDetailsType: { type: Boolean, default: false },
		useDepartmentForExpense: { type: Boolean, default: false },
		useDepartmentForSalary: { type: Boolean, default: false },
		filterType: { type: Number, validator: (v) => authorizedFilterTypeMode.includes(v), required: true },
		hideExpenseDetailsDepartment: { type: Boolean, default: false, required: false },
		hideExpenseDetailsCategory: { type: Boolean, default: false, required: false },
		hideProcessFilter: { type: Boolean, default: true, required: false },
		hideInvoicedToProduction: { type: Boolean, default: true, required: false }
	},
	data() {
		return {
			filterActive: {
				invoicedToProduction: null,
				processed: null,
				forForecastReportOnly: null,
				country: '',
				state: '',
				city: '',
				userDepartment: null,
				userFunction: null,
				startDate: '',
				endDate: '',
				category: null,
				personId: null,
				status: null,
				type: null,
				department: null, // Department for expense
				supplierId: null,
				source: null,
				co2Type: null,
				reviewStatus: [],
				templateId: null
			},
			tagVisible: false,
			countryNames: [],
			stateNames: [],
			functionNames: [],
			suppliers: [],
			mapCategories: [],
			haveFilter: false,
			users: [],
			cookiesName: 'filter_budget_advanced',
			showModalGlobalFilter: false,
			testState: [
				{ text: 'test 1', value: 'test 1' },
				{ text: 'test 2', value: 'test 2' },
				{ text: 'test 3', value: 'test 3' }
			],
			stateDate: {
				startDate: null,
				endDate: null
			},
			carbonTemplates: []
		};
	},
	computed: {
		...mapGetters({
			allDepartements: 'expenses/departments',
			allDepartementsForSalary: 'expenses/departmentsForSalary',
			filterBudget: 'global-filter/filterBudget'
		}),

		reviewStatusOptions() {
			let menues = this.FormMenu(CARBON_VERIFIED_STATUS_MENU);

			let result = [];
			menues.map((option) => {
				result.push({ id: option.value, label: option.text });
			});

			return result;
		},

		coTwoTypeOptions() {
			return this.FormMenu(1334);
		},

		sourceOptions() {
			return this.FormMenu(11006);
		},

		disableCategorySelector() {
			if (!this.useDepartmentForSalary) {
				if (this.filterActive.department === -1 || _.isNil(this.filterActive.department)) {
					return true;
				}

				return false;
			}
			return false;
		},
		optionsForCountries() {
			return this.countryNames;
		},
		optionsForStates() {
			return this.stateNames;
		},
		mapSuppliers() {
			const suppliers = this.suppliers.map((d) => {
				return {
					value: parseInt(d.id),
					text: d.name
				};
			});
			this.filterActive.supplierId = null;

			return [...suppliers];
		},
		canApplyFilter() {
			return (
				this.filterActive.country ||
				this.filterActive.state !== '' ||
				this.filterActive.city !== '' ||
				this.filterActive.userDepartment ||
				this.filterActive.userFunction ||
				this.filterActive.supplierId ||
				this.filterActive.startDate ||
				this.filterActive.endDate ||
				this.filterActive.personId ||
				this.filterActive.status ||
				this.filterActive.status === 0 ||
				this.filterActive.type ||
				this.filterActive.type === 0 ||
				this.filterActive.department ||
				this.filterActive.department === 0 ||
				this.filterActive.category ||
				this.filterActive.source ||
				this.filterActive.co2Type ||
				this.filterActive.co2Type === 0 ||
				this.filterActive.reviewStatus.length > 0 ||
				this.filterActive.processed !== null ||
				this.filterActive.forForecastReportOnly !== null ||
				this.filterActive.invoicedToProduction !== null ||
				this.filterActive.templateId ||
				+this.filterActive.templateId > 0
			);
		},
		statuses() {
			return this.FormMenu(1008);
		},
		typeExpenses() {
			return [
				// { value: -1, text: this.FormMSG(369, 'Select a type') },
				{ value: 0, text: this.FormMSG(327, 'Ticket') },
				{ value: 1, text: this.FormMSG(328, 'Invoice') },
				{ value: 2, text: this.FormMSG(329, 'Travel') }
			];
		},
		titleExpenseDetail() {
			return this.labelExpenseDetails;
		}
	},
	watch: {
		filterBudget: {
			async handler(newVal) {
				if (this.filterType === 0) {
					if (!_.isNil(newVal.forBudgetPerCategory)) {
						this.filterActive = newVal.forBudgetPerCategory;
					}
				} else if (this.filterType === 1) {
					if (!_.isNil(newVal.forBudgetSalaries)) {
						this.filterActive = newVal.forBudgetSalaries;
					}
				} else if (this.filterType === 2) {
					if (!_.isNil(newVal.forBudgetExpenses)) {
						this.filterActive = newVal.forBudgetExpenses;
						// console.log(this.filterActive)
						// alert("EXPENSES")
					}
				} else if (this.filterType === 3) {
					if (!_.isNil(newVal.forBudgetPO)) {
						this.filterActive = newVal.forBudgetPO;
					}
				} else if (this.filterType === 4) {
					if (!_.isNil(newVal.forBudgetExpenseSheet)) {
						this.filterActive = newVal.forBudgetExpenseSheet;
					}
				} else if (this.filterType === 5) {
					if (!_.isNil(newVal.forGreenTable)) {
						this.filterActive = newVal.forGreenTable;
					}
				}

				if (this.filterActive.userDepartment) {
					await this.handleChangeDepartment(this.filterActive.userDepartment);
				}

				// if (this.filterActive.department) {
				// 	await this.handleInputDepartmentExpense(this.filterActive.department);
				// }

				if (this.filterActive.country) {
					await this.handleChangeCountry(this.filterActive.country);
				}

				this.getHaveFilter();
				if (this.haveFilter) {
					this.tagVisible = true;
				}
			},
			immediate: true
			// deep: true
		}
	},
	async created() {
		await this.loadCountryNames();
		await this.getSuppliers();
		await this.getUsers();
		await this.getAllDepartments();
		await this.getCategories();

		if (this.filterType === 5) {
			await this.getCarbonTemplates();
		}
	},
	methods: {
		...mapActions({
			setMyFilterActive: 'global-filter/setMyFilterActive',
			getAllDepartments: 'expenses/getAllDepartments'
		}),
		async getCarbonTemplates() {
			this.carbonTemplates = await getCarbonTemplates({}, true);
		},
		handleInputReviewStatus(payload) {
			this.filterActive.reviewStatus = payload;
		},
		redirectToImportScreen() {
			this.$router.push('/imports');
		},
		setText(text) {
			if (text !== undefined && text.length < 4) {
				return text + ' ...';
			}
			return text;
		},
		setTextValue(text) {
			if (text !== undefined) {
				if (text.length > 20) {
					return text.substring(0, 17) + ' ...';
				}
				return text.substring(0, 20);
			}
		},
		handleChangeStartDate() {
			// alert('HERE');
			// alert('HERE');
		},
		handleInputState(payload) {
			this.filterActive.state = payload;
		},
		reduceSupplier(option) {
			return parseInt(option.id);
		},
		handleInputSupplier(payload) {
			this.filterActive.supplierId = payload;
		},
		async handleFunctionInput(payload) {
			// console.log(payload);
			this.filterActive.userFunction = payload;
			this.filterActive.personId = null;
			await this.getUsers(+this.filterActive.userDepartment, +payload);
		},
		handleInputStartDate(payload) {
			this.filterActive.startDate = payload;
			this.stateDate.startDate = null;
		},
		handleInputEndDate(payload) {
			this.filterActive.endDate = payload;
			this.stateDate.endDate = null;
		},
		async handleInputDepartmentExpense(payload, initCategory = false) {
			// if (initCategory) {
			// 	this.filterActive.category = null;
			// }
			// if (_.isNil(payload)) {
			// 	this.mapCategories = [];
			// 	this.filterActive.category = null;
			// } else {
			// 	await this.getCategories(0);
			// }
		},
		async handleInputDepartmentSalaries(payload, initCategory = false) {
			if (initCategory) {
				this.filterActive.category = null;
			}
			// alert('miditra ato');
			await this.getCategories(1);
		},
		async getCategories(contentType = 0) {
			this.mapCategories = [];
			await getBudgetHierarchicalCategoryJson(-2, true, 0, -1, false).then((result) => {
				this.mapCategories = removeAttributeTree(result, 'children', null);
			});
		},
		handleClickRefreshData() {
			this.$bus.$emit('filter-budget-advanced:refresh-data');
		},
		async getUsers(departmentId = -1, functionId = 0) {
			const users = await getUsers({ myProject: true }, 0, departmentId, functionId);
			let result = [];

			if (users.length > 0) {
				users.forEach((user) => {
					result.push({
						value: parseInt(user.id, 10),
						text: user.fullName
					});
				});
			}

			this.users = result;
		},
		async handleClickAplyFilter() {
			this.setMyFilterActive({
				newValue: { ...this.filterActive, id: 0 },
				filterType: this.filterType
			});
			this.emitFilters();
			this.getHaveFilter();
		},
		handleClickExportSelection() {
			if (this.filterActive.startDate === '' || this.filterActive.endDate === '') {
				this.tagVisible = true;
				if (this.filterActive.startDate === '') {
					this.stateDate.startDate = false;
				}
				if (this.filterActive.endDate === '') {
					this.stateDate.endDate = false;
				}
				this.createToastForMobile(this.FormMSG(351, 'Error'), this.FormMSG(352, 'You must choice a from and to date'), '', 'danger');
			} else {
				this.$emit('filter-budget-advanced:click-selection', {
					startDate: this.filterActive.startDate.split('T')[0] + 'T00:00:00Z',
					endDate: this.filterActive.endDate.split('T')[0] + 'T23:59:59Z',
					userDepartment: !_.isNil(this.filterActive.userDepartment) ? +this.filterActive.userDepartment : null,
					userFunction: !_.isNil(this.filterActive.userFunction) ? +this.filterActive.userFunction : null,
					userId: !_.isNil(this.filterActive.personId) ? +this.filterActive.personId : null,
					status: !_.isNil(this.filterActive.status) ? +this.filterActive.status : null,
					department: !_.isNil(this.filterActive.department) ? +this.filterActive.department : null,
					category: !_.isNil(this.filterActive.category) ? +this.filterActive.category : null,
					supplierId: !_.isNil(this.filterActive.supplierId) ? +this.filterActive.supplierId : null,
					country: this.filterActive.country,
					state: this.filterActive.state,
					city: this.filterActive.city,
					type: !_.isNil(this.filterActive.type) ? +this.filterActive.type : null,
					sendEmail: false,
					processed: this.filterActive.processed,
					forForecastReportOnly: this.filterActive.forForecastReportOnly,
					invoicedToProduction: this.filterActive.invoicedToProduction
				});
			}
		},
		emitFilters() {
			const resultReturnEmit = this.returnFilter(this.filterActive);
			this.$bus.$emit('filter-budget-advanced:change', {
				...resultReturnEmit,
				filterType: this.filterType
			});
		},
		async handleChangeDepartment(payload, sendEmit = true) {
			this.filterActive.userFunction = null;
			this.filterActive.personId = null;
			await this.getFunctions(+payload);
			await this.getUsers(+payload);
		},
		async getFunctions(id) {
			const functions = await getFunctions(id);

			let resultBudgetDetails = [];

			for (let i = 0; i < functions.length; i++) {
				const element = functions[i];
				resultBudgetDetails.push({
					value: element.value,
					message: element.costCenter + ' - ' + element.message
				});
			}

			this.functionNames = resultBudgetDetails;
		},
		handleClickShowFilter(value) {
			this.tagVisible = value;
		},
		handleChangeCountry(sendEmit = true) {
			this.filterActive.state = '';
			this.$nextTick(async () => {
				await this.loadStateNames();
			});
		},
		async loadStateNames() {
			var ar = [];

			var stateData = await getSuppliersStates(this.filterActive.country);

			for (var i = 1; i < stateData.length; i++) {
				var curMenuItem = {};
				curMenuItem.value = stateData[i];
				curMenuItem.text = stateData[i];
				ar.push(curMenuItem);
			}

			this.stateNames = ar;
		},
		async loadCountryNames() {
			var ar = [];

			ar.push({
				value: '',
				text: this.FormMSG(122, 'Select country')
			});

			var countryData = await getSuppliersCountries();

			for (var i = 1; i < countryData.length; i++) {
				var curMenuItem = {};
				curMenuItem.value = countryData[i];
				curMenuItem.text = countryData[i];
				ar.push(curMenuItem);
			}

			this.countryNames = ar;
		},
		resetFilter() {
			this.filterActive = {
				country: '',
				state: '',
				city: '',
				userDepartment: null,
				userFunction: null,
				supplierId: null,
				startDate: '',
				endDate: '',
				personId: null,
				status: null,
				type: null,
				category: null,
				department: null,
				processed: null,
				forForecastReportOnly: null,
				invoicedToProduction: null,
				source: null,
				co2Type: null,
				reviewStatus: []
			};

			this.setMyFilterActive({
				newValue: null,
				filterType: this.filterType
			});
			this.emitClearFilters();
			this.getHaveFilter();
		},
		emitClearFilters() {
			this.$bus.$emit('filter-budget-advanced:clear-filters', this.filterActive);
		},
		getHaveFilter() {
			this.haveFilter =
				this.filterActive.country !== '' ||
				this.filterActive.state !== '' ||
				this.filterActive.city !== '' ||
				this.filterActive.userDepartment ||
				this.filterActive.userFunction ||
				this.filterActive.supplierId ||
				this.filterActive.startDate !== '' ||
				this.filterActive.endDate !== '' ||
				this.filterActive.personId ||
				this.filterActive.status ||
				this.filterActive.type ||
				this.filterActive.department ||
				this.filterActive.category ||
				this.filterActive.source ||
				this.filterActive.co2Type ||
				this.filterActive.co2Type === 0 ||
				this.filterActive.reviewStatus.length > 0 ||
				this.filterActive.processed !== null ||
				this.filterActive.forForecastReportOnly !== null ||
				this.filterActive.invoicedToProduction !== null ||
				this.filterActive.templateId ||
				+this.filterActive.templateId > 0;

			this.$emit('filter-budget-advanced:have-filter', { haveFilter: this.haveFilter });
		},
		async getSuppliers() {
			const result = await getSuppliers({
				name: null,
				type: 0
			});
			this.suppliers = result;
		}
	}
};
</script>
