export default {
	name: 'BudgetMixin',

	methods: {
		returnFilter(val) {
			let filterResult = {
				city: val.city,
				state: val.state,
				country: val.country,
				department: val.department,
				userDepartment: val.userDepartment,
				userFunction: val.userFunction,
				startDate: val.startDate + 'T00:00:00Z',
				endDate: val.endDate + 'T23:59:00Z',
				personId: val.personId,
				supplierId: val.supplierId,
				status: val.status,
				type: val.type,
				category: val.category,
				processed: val.processed,
				forForecastReportOnly: val.forForecastReportOnly,
				invoicedToProduction: val.invoicedToProduction,
				source: val.source,
				co2Type: val.co2Type,
				reviewStatus: val.reviewStatus,
				templateId: val.templateId
			};

			if (val.country === '' || _.isNil(val.country)) delete filterResult.country;
			if (val.state === '' || _.isNil(val.state)) delete filterResult.state;
			if (val.city === '' || _.isNil(val.city)) {
				delete filterResult.city;
				delete filterResult.city;
			}
			if (_.isNil(val.department)) delete filterResult.department;
			if (_.isNil(val.userDepartment)) delete filterResult.userDepartment;
			if (val.userFunction === null || _.isNil(val.userFunction)) delete filterResult.userFunction;
			if (val.personId === null || _.isNil(val.personId)) delete filterResult.personId;
			if (val.supplierId === null || _.isNil(val.supplierId)) delete filterResult.supplierId;
			if (val.source === null || _.isNil(val.source)) delete filterResult.source;
			if (val.co2Type === null || _.isNil(val.co2Type)) delete filterResult.co2Type;
			if (val.templateId === null || _.isNil(val.templateId)) delete filterResult.templateId;
			if (val.reviewStatus.length === 0) delete filterResult.reviewStatus;
			// if (val.startDate.split('T')[0] === '') delete filterResult.startDate
			// if (val.endDate.split('T')[0] === '') delete filterResult.endDate
			this.checkDateForReturnEmit(val, 'startDate', filterResult);
			this.checkDateForReturnEmit(val, 'endDate', filterResult);
			if (val.status === null || _.isNil(val.status)) delete filterResult.status;
			if (val.type === null || _.isNil(val.type)) delete filterResult.type;
			if (val.category === null || _.isNil(val.category)) delete filterResult.category;
			if (val.processed === null) delete filterResult.processed;
			if (val.forForecastReportOnly === null) delete filterResult.forForecastReportOnly;
			if (val.invoicedToProduction === null) delete filterResult.invoicedToProduction;

			return filterResult;
		},
		checkDateForReturnEmit(val, field, filterResult) {
			if (val[field]) {
				if (val[field] === '') {
					delete filterResult[field];
				}
			} else {
				delete filterResult[field];
			}
		}
	}
};
