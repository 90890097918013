var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form mb-3" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "d-flex align-items-center", attrs: { cols: "6" } },
            [
              _c(
                "button",
                {
                  staticClass:
                    "d-flex justify-content-end align-items-center text-color-blue-streak fw-500 fs-14 btn-transparent mr-3",
                  on: {
                    click: function ($event) {
                      return _vm.handleClickShowFilter(!_vm.tagVisible)
                    },
                  },
                },
                [
                  _vm.tagVisible
                    ? _c("div", [
                        _vm._v(_vm._s(_vm.FormMSG(25, "Hide filter"))),
                      ])
                    : _vm._e(),
                  !_vm.tagVisible
                    ? _c("div", [
                        _vm._v(_vm._s(_vm.FormMSG(26, "Show filter"))),
                      ])
                    : _vm._e(),
                  _vm.tagVisible
                    ? _c("chevron-up", {
                        staticClass: "ml-1",
                        attrs: { size: 20, color: "#225CBD" },
                      })
                    : _vm._e(),
                  !_vm.tagVisible
                    ? _c("chevron-down", {
                        staticClass: "ml-1",
                        attrs: { size: 20, color: "#225CBD" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass:
                    "d-flex justify-content-end align-items-center fw-500 fs-14 btn-transparent",
                  class: {
                    "text-color-blue-streak": !_vm.haveFilter,
                    "text-color-tree-sap": _vm.haveFilter,
                  },
                },
                [
                  _c("info", { staticClass: "mr-1", attrs: { size: 18 } }),
                  _vm.haveFilter
                    ? _c("div", [
                        _vm._v(_vm._s(_vm.FormMSG(27, "Active filter"))),
                      ])
                    : _vm._e(),
                  !_vm.haveFilter
                    ? _c("div", [
                        _vm._v(_vm._s(_vm.FormMSG(101, "No active filter"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm.haveFilter
                ? _c(
                    "b-button",
                    {
                      staticClass: "ml-5",
                      attrs: { variant: "outline-primary", size: "sm" },
                      on: { click: _vm.resetFilter },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(28, "Reset filter")) +
                          "\n\t\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("div", { staticClass: "float-right" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-inline-block d-flex justify-content-end align-items-center",
                },
                [
                  !_vm.hideButtonRefreshData
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "mr-2 d-flex justify-content-end align-items-center",
                          attrs: { variant: "success" },
                          on: { click: _vm.handleClickRefreshData },
                        },
                        [
                          _c("RefreshCw", { attrs: { size: 16 } }),
                          _c(
                            "div",
                            {
                              staticClass: "ml-2",
                              staticStyle: { "margin-top": "1px" },
                            },
                            [_vm._v(_vm._s(_vm.FormMSG(136, "Refresh data")))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.hideButtonExport
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "d-flex mr-2 justify-content-end align-items-center",
                          attrs: { variant: "primary" },
                          on: { click: _vm.handleClickExportSelection },
                        },
                        [
                          _c("Download", { attrs: { size: 16 } }),
                          _c(
                            "div",
                            {
                              staticClass: "ml-2",
                              staticStyle: { "margin-top": "1px" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.FormMSG(29, "Export selection"))
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showImport
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "d-flex justify-content-end align-items-center",
                          attrs: { variant: "primary" },
                          on: { click: _vm.redirectToImportScreen },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "1px",
                                width: "130px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.FormMSG(290, "Import")))]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-collapse",
        { staticClass: "mt-2", attrs: { visible: _vm.tagVisible } },
        [
          !_vm.hideDate
            ? _c(
                "fieldset",
                {
                  staticClass:
                    "scheduler-border border-groove-blue-streak pb-0",
                },
                [
                  _c(
                    "legend",
                    { staticClass: "scheduler-border text-color-blue-streak" },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(145, "When")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(41, "Start date") } },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "mb-3" },
                                [
                                  _c("b-datepicker", {
                                    attrs: {
                                      locale: _vm.lang,
                                      placeholder: _vm.FormMSG(
                                        42,
                                        "dd/mm/yyyy"
                                      ),
                                      state: _vm.stateDate.startDate,
                                    },
                                    on: { input: _vm.handleInputStartDate },
                                    model: {
                                      value: _vm.filterActive.startDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filterActive,
                                          "startDate",
                                          $$v
                                        )
                                      },
                                      expression: "filterActive.startDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(43, "End date") } },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "mb-3" },
                                [
                                  _c("b-datepicker", {
                                    attrs: {
                                      locale: _vm.lang,
                                      placeholder: _vm.FormMSG(
                                        42,
                                        "dd/mm/yyyy"
                                      ),
                                      state: _vm.stateDate.endDate,
                                    },
                                    on: { input: _vm.handleInputEndDate },
                                    model: {
                                      value: _vm.filterActive.endDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filterActive,
                                          "endDate",
                                          $$v
                                        )
                                      },
                                      expression: "filterActive.endDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.hidePersonSection
            ? _c(
                "fieldset",
                {
                  staticClass:
                    "scheduler-border border-groove-blue-streak pb-0",
                },
                [
                  _c(
                    "legend",
                    { staticClass: "scheduler-border text-color-blue-streak" },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(146, "Person")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(35, "Department") } },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: [
                                    {
                                      value: -1,
                                      message: _vm.FormMSG(
                                        285,
                                        "All departments"
                                      ),
                                    },
                                    ..._vm.allDepartements,
                                  ],
                                  label: "message",
                                  reduce: (option) => option.value,
                                  placeholder: _vm.FormMSG(
                                    36,
                                    "Select department"
                                  ),
                                  clearable: false,
                                },
                                on: { input: _vm.handleChangeDepartment },
                                model: {
                                  value: _vm.filterActive.userDepartment,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filterActive,
                                      "userDepartment",
                                      $$v
                                    )
                                  },
                                  expression: "filterActive.userDepartment",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(37, "Function") } },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.functionNames,
                                  label: "message",
                                  reduce: (option) => option.value,
                                  placeholder: _vm.FormMSG(
                                    38,
                                    "Select function"
                                  ),
                                  disabled:
                                    _vm.filterActive.userDepartment === null ||
                                    _vm.filterActive.userDepartment === -1,
                                },
                                on: { input: _vm.handleFunctionInput },
                                model: {
                                  value: _vm.filterActive.userFunction,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filterActive,
                                      "userFunction",
                                      $$v
                                    )
                                  },
                                  expression: "filterActive.userFunction",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(214, "User") } },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.users,
                                  label: "text",
                                  reduce: (option) => option.value,
                                  placeholder: _vm.FormMSG(
                                    218,
                                    "Select a user"
                                  ),
                                },
                                model: {
                                  value: _vm.filterActive.personId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterActive, "personId", $$v)
                                  },
                                  expression: "filterActive.personId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.hideExpenseDetailsSection
            ? _c(
                "fieldset",
                {
                  staticClass:
                    "scheduler-border border-groove-blue-streak pb-0",
                },
                [
                  _c(
                    "legend",
                    { staticClass: "scheduler-border text-color-blue-streak" },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.titleExpenseDetail) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(295, "Status") } },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.statuses,
                                  label: "text",
                                  reduce: (option) => option.value,
                                  placeholder: _vm.FormMSG(
                                    265,
                                    "Select a status"
                                  ),
                                },
                                model: {
                                  value: _vm.filterActive.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterActive, "status", $$v)
                                  },
                                  expression: "filterActive.status",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.useDepartmentForExpense &&
                      !_vm.hideExpenseDetailsDepartment
                        ? _c(
                            "b-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(35, "Department"),
                                  },
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      options: [
                                        {
                                          value: -1,
                                          message: _vm.FormMSG(
                                            285,
                                            "All departments"
                                          ),
                                        },
                                        ..._vm.allDepartements,
                                      ],
                                      label: "message",
                                      reduce: (option) => option.value,
                                      placeholder: _vm.FormMSG(
                                        296,
                                        "Select a department"
                                      ),
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.handleInputDepartmentExpense(
                                          $event,
                                          true
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.filterActive.department,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filterActive,
                                          "department",
                                          $$v
                                        )
                                      },
                                      expression: "filterActive.department",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.hideExpenseDetailsCategory
                        ? _c(
                            "b-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(151, "Category"),
                                  },
                                },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      multiple: false,
                                      options: _vm.mapCategories,
                                      "disable-branch-nodes": true,
                                      placeholder: _vm.FormMSG(
                                        297,
                                        "Select a category"
                                      ),
                                      clearable: false,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "option-label",
                                          fn: function ({ node }) {
                                            return _c("div", {}, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "treeselect-label",
                                                  attrs: { title: node.label },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(node.label) +
                                                      "\n\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ])
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4090509567
                                    ),
                                    model: {
                                      value: _vm.filterActive.category,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filterActive,
                                          "category",
                                          $$v
                                        )
                                      },
                                      expression: "filterActive.category",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.filterType === 2 || _vm.filterType === 4
                        ? _c(
                            "b-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: _vm.FormMSG(347, "Type") } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      options: _vm.typeExpenses,
                                      label: "text",
                                      reduce: (option) => option.value,
                                      placeholder: _vm.FormMSG(
                                        326,
                                        "Select a type"
                                      ),
                                    },
                                    model: {
                                      value: _vm.filterActive.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filterActive, "type", $$v)
                                      },
                                      expression: "filterActive.type",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.hideProcessFilter
                        ? _c(
                            "b-col",
                            [
                              _c("b-form-group", {
                                attrs: { label: _vm.FormMSG(514, "Processed") },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ ariaDescribedby }) {
                                        return [
                                          _c(
                                            "b-form-radio-group",
                                            {
                                              attrs: {
                                                id: "radio-group-2",
                                                "aria-describedby":
                                                  ariaDescribedby,
                                                name: "radio-sub-component",
                                              },
                                              model: {
                                                value:
                                                  _vm.filterActive.processed,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filterActive,
                                                    "processed",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filterActive.processed",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-radio",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: { value: false },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(515, "No")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-form-radio",
                                                {
                                                  staticClass: "ml-2 mt-2",
                                                  attrs: { value: true },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(516, "Yes")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1554266057
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.hideInvoicedToProduction
                        ? _c(
                            "b-col",
                            [
                              _c("b-form-group", {
                                attrs: {
                                  label: _vm.FormMSG(
                                    553,
                                    "Invoiced to production"
                                  ),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ ariaDescribedby }) {
                                        return [
                                          _c(
                                            "b-form-radio-group",
                                            {
                                              attrs: {
                                                id: "radio-group-3",
                                                "aria-describedby":
                                                  ariaDescribedby,
                                                name: "radio-sub-component-2",
                                              },
                                              model: {
                                                value:
                                                  _vm.filterActive
                                                    .invoicedToProduction,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filterActive,
                                                    "invoicedToProduction",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filterActive.invoicedToProduction",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-radio",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: { value: false },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(515, "No")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-form-radio",
                                                {
                                                  staticClass: "ml-2 mt-2",
                                                  attrs: { value: true },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(516, "Yes")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4227584944
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.hideSupplierSection
            ? _c(
                "fieldset",
                {
                  staticClass:
                    "scheduler-border border-groove-blue-streak pb-0",
                },
                [
                  _c(
                    "legend",
                    { staticClass: "scheduler-border text-color-blue-streak" },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(149, "Supplier")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(212, "Supplier") } },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.suppliers,
                                  label: "name",
                                  reduce: _vm.reduceSupplier,
                                  placeholder: _vm.FormMSG(
                                    213,
                                    "Select a supplier"
                                  ),
                                },
                                on: { input: _vm.handleInputSupplier },
                                model: {
                                  value: _vm.filterActive.supplierId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filterActive,
                                      "supplierId",
                                      $$v
                                    )
                                  },
                                  expression: "filterActive.supplierId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(30, "Country") } },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.optionsForCountries,
                                  label: "text",
                                  reduce: (option) => option.value,
                                  placeholder: _vm.FormMSG(
                                    31,
                                    "Select country"
                                  ),
                                },
                                on: { input: _vm.handleChangeCountry },
                                model: {
                                  value: _vm.filterActive.country,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterActive, "country", $$v)
                                  },
                                  expression: "filterActive.country",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(32, "State / Region"),
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.stateNames,
                                  placeholder: _vm.FormMSG(128, "Select state"),
                                  label: "text",
                                  reduce: (option) => option.value,
                                  disabled: !_vm.filterActive.country,
                                },
                                on: { input: _vm.handleInputState },
                                model: {
                                  value: _vm.filterActive.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterActive, "state", $$v)
                                  },
                                  expression: "filterActive.state",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(33, "City") } },
                            [
                              _c("b-form-input", {
                                attrs: { placeholder: _vm.FormMSG(33, "City") },
                                model: {
                                  value: _vm.filterActive.city,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterActive, "city", $$v)
                                  },
                                  expression: "filterActive.city",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.hideEntryDetailSection
            ? _c(
                "fieldset",
                {
                  staticClass:
                    "scheduler-border border-groove-blue-streak pb-0",
                },
                [
                  _c(
                    "legend",
                    { staticClass: "scheduler-border text-color-blue-streak" },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(521, "EntryDetail")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(522, "Source") } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "text",
                                  options: _vm.sourceOptions,
                                  reduce: (option) => option.value,
                                  placeholder: _vm.FormMSG(
                                    523,
                                    "Select a source"
                                  ),
                                  clearable: false,
                                },
                                model: {
                                  value: _vm.filterActive.source,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterActive, "source", $$v)
                                  },
                                  expression: "filterActive.source",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(524, "CO2 Type") } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "text",
                                  options: _vm.coTwoTypeOptions,
                                  placeholder: _vm.FormMSG(
                                    525,
                                    "Select a category"
                                  ),
                                  reduce: (option) => option.value,
                                  clearable: false,
                                },
                                model: {
                                  value: _vm.filterActive.co2Type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterActive, "co2Type", $$v)
                                  },
                                  expression: "filterActive.co2Type",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(526, "Review status"),
                              },
                            },
                            [
                              _c("treeselect", {
                                attrs: {
                                  options: _vm.reviewStatusOptions,
                                  "v-model": _vm.filterActive.reviewStatus,
                                  value: _vm.filterActive.reviewStatus,
                                  placeholder: _vm.FormMSG(
                                    894,
                                    "Please select ..."
                                  ),
                                  multiple: true,
                                  flat: "",
                                  clearable: false,
                                },
                                on: { input: _vm.handleInputReviewStatus },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "value-label",
                                      fn: function ({ node }) {
                                        return _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center",
                                            staticStyle: {
                                              height: "12px !important",
                                            },
                                            attrs: {
                                              title: _vm.setTextValue(
                                                node.label
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "font-size":
                                                    "10px !important",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.setTextValue(node.label)
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      },
                                    },
                                    {
                                      key: "option-label",
                                      fn: function ({ node }) {
                                        return _c("div", {}, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "treeselect-label",
                                              attrs: {
                                                title: _vm.setText(node.label),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.setText(node.label))
                                              ),
                                            ]
                                          ),
                                        ])
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3556820732
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(212, "Supplier") } },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.suppliers,
                                  label: "name",
                                  reduce: _vm.reduceSupplier,
                                  placeholder: _vm.FormMSG(
                                    213,
                                    "Select a supplier"
                                  ),
                                  clearable: false,
                                },
                                on: { input: _vm.handleInputSupplier },
                                model: {
                                  value: _vm.filterActive.supplierId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filterActive,
                                      "supplierId",
                                      $$v
                                    )
                                  },
                                  expression: "filterActive.supplierId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(151, "Category") } },
                            [
                              _c("treeselect", {
                                attrs: {
                                  multiple: false,
                                  options: _vm.mapCategories,
                                  "disable-branch-nodes": true,
                                  placeholder: _vm.FormMSG(
                                    297,
                                    "Select a category"
                                  ),
                                  clearable: false,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "option-label",
                                      fn: function ({ node }) {
                                        return _c("div", {}, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "treeselect-label",
                                              attrs: { title: node.label },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(node.label) +
                                                  "\n\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ])
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4090509567
                                ),
                                model: {
                                  value: _vm.filterActive.category,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterActive, "category", $$v)
                                  },
                                  expression: "filterActive.category",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(289, "Template") } },
                            [
                              _c("v-select", {
                                attrs: {
                                  multiple: false,
                                  options: _vm.carbonTemplates,
                                  placeholder: _vm.FormMSG(
                                    397,
                                    "Please select ..."
                                  ),
                                  label: "name",
                                  reduce: (option) => +option.id,
                                  clearable: false,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "option-label",
                                      fn: function ({ node }) {
                                        return _c("div", {}, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "treeselect-label",
                                              attrs: { title: node.label },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(node.label) +
                                                  "\n\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ])
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4090509567
                                ),
                                model: {
                                  value: _vm.filterActive.templateId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filterActive,
                                      "templateId",
                                      $$v
                                    )
                                  },
                                  expression: "filterActive.templateId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("b-form-group", {
                            attrs: {
                              label: _vm.FormMSG(392, "Forecast/Final report"),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ ariaDescribedby }) {
                                    return [
                                      _c(
                                        "b-form-radio-group",
                                        {
                                          attrs: {
                                            id: "radio-group-2",
                                            "aria-describedby": ariaDescribedby,
                                            name: "radio-forecast",
                                          },
                                          model: {
                                            value:
                                              _vm.filterActive
                                                .forForecastReportOnly,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filterActive,
                                                "forForecastReportOnly",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "filterActive.forForecastReportOnly",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-radio",
                                            {
                                              staticClass: "mt-2",
                                              attrs: { value: false },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(515, "No"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-form-radio",
                                            {
                                              staticClass: "ml-2 mt-2",
                                              attrs: { value: true },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(516, "Yes"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3064180772
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "2", offset: "10" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "success",
                        block: "",
                        disabled: !_vm.canApplyFilter,
                      },
                      on: { click: _vm.handleClickAplyFilter },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(291, "Apply filter")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }