<template>
	<div class="d-flex">
		<div v-if="filterActive && !showInputFilter" class="container-field p-5-px mt-n-5 mr-3 pos-relative">
			<b-row>
				<b-col>
					<b-input-group>
						<b-form-input :value="filterActive.filterName" disabled />
						<b-input-group-append class="cursor-pointer">
							<b-input-group-text class="btn-search">
								<button class="btn-transparent" @click="handleClickInput(filterActive)">
									<Edit2 color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" />
								</button>
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-col>
			</b-row>
		</div>
		<div v-if="showInputFilter" class="container-field p-5-px mt-n-5 mr-3 pos-relative">
			<div class="pos-absolute custom-dissimissible-filter" style="top: -12px; right: -7px">
				<span class="cursor-pointer" @click="showInputFilter = false">
					<XCircle :size="16" color="#06263e" fill="#f3f4f5" />
				</span>
			</div>
			<b-row>
				<b-col cols="6">
					<b-form-input v-model="filterName" size="sm" :placeholder="FormMSG(31, 'Filter name')" />
				</b-col>
				<b-col cols="6">
					<div class="float-right">
						<b-button v-if="forCreateFilter" variant="outline-primary" size="sm" @click="handleSubmit">
							{{ FormMSG(66, 'Create') }}
						</b-button>
						<b-button v-if="!forCreateFilter" variant="outline-danger" size="sm" @click="handleClickDeleteFilter">
							{{ FormMSG(57, 'Delete') }}
						</b-button>
					</div>
				</b-col>
			</b-row>
		</div>
		<div>
			<b-dropdown id="dropdown-1" variant="outline-success" class="mr-2">
				<template #button-content>
					<span> <SlidersHorizontal :size="16" /> {{ FormMSG(294, 'Saved filter') }} </span>
				</template>
				<b-dropdown-item @click="handleClickNewFilter"> <PlusCircle :size="16" /> {{ FormMSG(291, 'Save current filter') }} </b-dropdown-item>
				<b-dropdown-divider v-if="filterOptions.length > 0"></b-dropdown-divider>
				<b-dropdown-item
					v-for="filter in filterOptions"
					:key="filter.id"
					:active="filterActive && filter.id === filterActive.id"
					@click="handleClickFilter(filter)"
				>
					{{ filter.filterName }}
				</b-dropdown-item>
			</b-dropdown>
		</div>
	</div>
</template>

<script>
import { Settings, PlusCircle, SlidersHorizontal, XCircle, Edit2 } from 'lucide-vue';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'GlobalFilter',

	mixins: [languageMessages, globalMixin],

	props: {
		filterType: { type: Number, default: 0 },
		filterData: { type: Object, default: null, required: false }
	},

	components: {
		Settings,
		PlusCircle,
		SlidersHorizontal,
		XCircle,
		Edit2
	},

	data() {
		return {
			filterName: '',
			dataForEdit: null,
			showInputFilter: false,
			myChoiceFilterActive: null
		};
	},

	computed: {
		...mapGetters({
			filterBudget: 'global-filter/filterBudget',
			listFilters: 'global-filter/listFilters'
		}),

		forCreateFilter() {
			if (_.isNil(this.dataForEdit)) {
				return true;
			}

			return false;
		},

		filterActive() {
			if (this.filterType === 0) {
				return this.filterBudget.forBudgetPerCategory;
			} else if (this.filterType === 1) {
				return this.filterBudget.forBudgetSalaries;
			} else if (this.filterType === 2) {
				return this.filterBudget.forBudgetExpenses;
			} else if (this.filterType === 3) {
				return this.filterBudget.forBudgetPO;
			}
			return null;
		},

		filterOptions() {
			if (this.filterType === 0) {
				return this.listFilters.forBudgetPerCategory;
			} else if (this.filterType === 1) {
				return this.listFilters.forBudgetSalaries;
			} else if (this.filterType === 2) {
				return this.listFilters.forBudgetExpenses;
			} else if (this.filterType === 3) {
				return this.listFilters.forBudgetPO;
			}
			return [];
		}
	},

	async created() {
		// await this.getListFilters()
	},

	methods: {
		...mapActions({
			addFilter: 'global-filter/addFilter',
			updateFilter: 'global-filter/updateFilter',
			deleteFilter: 'global-filter/deleteFilter'
		}),

		handleClickInput(filter) {
			this.dataForEdit = filter;
			this.filterName = filter.filterName;

			this.myChoiceFilterActive = filter;

			this.showInputFilter = true;
		},

		async handleClickDeleteFilter() {
			await this.deleteFilter({
				filterId: this.dataForEdit.id,
				filterType: this.filterType
			});

			this.createToastForMobile(this.FormMSG(51, 'Success'), this.FormMSG(56, 'Deleted filter successfully'));

			this.showInputFilter = false;
		},
		async handleClickFilter(filter) {
			this.dataForEdit = filter;
			this.filterName = filter.filterName;

			this.updateFilter({
				inputFilter: {
					isActive: true
				},
				filterId: filter.id
			});

			this.myChoiceFilterActive = filter;

			this.showInputFilter = true;
		},
		handleClickNewFilter() {
			this.dataForEdit = null;
			this.showInputFilter = true;
		},
		async handleSubmit() {
			await this.addFilter({
				filterName: this.filterName,
				isActive: true,
				filterType: this.filterType,
				...this.filterData,
				startDate: this.filterData.startDate + 'T00:00:00Z',
				endDate: this.filterData.endDate + 'T23:59:59Z',
				recordLimit: 100,
				recordOffset: 0,
				perPage: 100
			});

			this.createToastForMobile(this.FormMSG(51, 'Success'), this.FormMSG(52, 'Created filter successfully'));

			this.showInputFilter = false;
		},
		emitCloseEvent() {
			this.$emit('modal-global-filter:close');
		}
	}
};
</script>
